import doc               from '@widesk-core/decorators/doc';
import model             from '@widesk-core/decorators/model';
import AuditServiceModel from '@/models/AuditServiceModel';
import { computed }      from 'mobx';
import makeUrn           from '@/tools/makeUrn';

@model.urnResource('event')
@doc.path('/events/{?id}')
export default class EventModel extends AuditServiceModel {
	private declare _tsFilters: {
		'actor.urn.identifier': string;
		'subject.metadata.account_urn': string;
		'kind': string;
		'timestamp[gte]': string | Date | Dayjs;
		'timestamp[lte]': string | Date | Dayjs;
		'subject.urn.resource[eq]': string;
	};

	private declare _tsSorts: {
		'timestamp': string;
	};

	@doc.string declare kind: string;
	@doc.string declare parentUuid: string;
	@doc.string declare timestamp: string;
	@doc.string declare type: string;
	@doc.string declare uuid: string;

	@computed
	public get actor() {
		return this.get('actor', {});
	}

	@computed
	public get subject() {
		return this.get('subject', {});
	}

	@computed
	public get action() {
		switch (this.kind) {
			case 'created':
				return 'a créé';
			case 'updated':
				return 'a modifié';
			case 'deleted':
				return 'a supprimé';
			case 'email_delivered':
				return 'a délivré un email';
			default:
				return this.kind;
		}
	}

	@computed
	public get userUrn() {
		return this.actor.urn ? makeUrn(this.actor.urn) : '';
	}

	@computed
	public get subjectUrn() {
		return this.subject.urn ? makeUrn(this.subject.urn) : '';
	}
}